import { gql } from '@apollo/client';

export const GET_QUOTA_ADJUSTMENT = gql`
  query GetAllAdjustmentQuotas($order: [[String]], $limit: [Int], $state: [Int]) {
    getAllAdjustmentQuotas(order: $order, limit: $limit, state: $state) {
      aqId
      aqFrom
      aqUntil
      aqWhen
      aqDatCre
      aqSameMonths
      aqState
    }
  }
`;

export const CREATE_QUOTA_ADJUSTMENT = gql`
  mutation CreateAdjustmentQuotas($input: IAdjustmentQuotas) {
    createAdjustmentQuotas(input: $input) {
      aqId
      aqUntil
      aqWhen
      aqSameMonths
      aqState
      aqFrom
    }
  }
`;

export const UPDATE_QUOTA_ADJUSTMENT = gql`
  mutation UpdateAdjustmentQuotas($aqId: String, $input: IAdjustmentQuotas) {
    updateAdjustmentQuotas(aqId: $aqId, input: $input) {
      aqId
      aqFrom
      aqUntil
      aqWhen
      aqSameMonths
      aqState
    }
  }
`;

export const DELETE_QUOTA_ADJUSTMENT = gql`
  mutation DeleteAdjustmentQuotas($aqId: String, $aqState: Int) {
    deleteAdjustmentQuotas(aqId: $aqId, aqState: $aqState) {
      aqId
      aqFrom
      aqUntil
      aqWhen
      aqSameMonths
      aqState
    }
  }
`;
