import * as yup from 'yup';

const schema = yup.object({
  from: yup
    .number()
    .typeError('Campo numérico requerido')
    .required('Campo requerido')
    .min(1, 'El valor debe ser mayor o igual a 1')
    .max(31, 'El valor debe ser menor o igual a 31')
    .integer('El valor debe ser un número entero')
    .test(
      'from-less-than-to',
      'El valor de "Desde" debe ser menor a "Hasta"',
      function validationFrom(value) {
        const { to } = this.parent;
        return to === undefined || value < to;
      }
    ),
  to: yup
    .number()
    .typeError('Campo numérico requerido')
    .required('Campo requerido')
    .min(1, 'El valor debe ser mayor o igual a 1')
    .max(31, 'El valor debe ser menor o igual a 31')
    .integer('El valor debe ser un número entero')
    .test(
      'to-greater-than-from',
      'El valor de "Hasta" debe ser mayor a "Desde"',
      function validationTo(value) {
        const { from } = this.parent;
        return from === undefined || value > from;
      }
    ),
  sameMonth: yup.boolean().default(false),
  when: yup.string().typeError('Campo requerido').required('Campo requerido'),
});

export default schema;
