import { Theme } from '@mui/material';
import { TStyle } from './type.d';

export const styleSheet = (theme: Theme) => {
  const style: TStyle = {
    page: { width: '100%', padding: '20px' },
    title: {
      fontWeight: 'bold',
      fontSize: '1.3em',
      color: `${theme.palette.primary.dark}`,
    },
    card: {
      padding: '20px',
      borderRadius: '5px',
      background: '#fff',
      boxShadow: '0px 3px 5px 0px #dcdcdc',
    },
    subTitle: {
      fontWeight: 'bold',
      fontSize: '1em',
      textAlign: 'left',
      borderBottom: `1px solid ${theme.palette.primary.dark}`,
      color: `${theme.palette.primary.dark}`,
    },
    end: { display: 'flex', justifyContent: 'flex-end', alignItems: 'center' },
    button: {
      background: theme.palette.secondary.main,
      height: '30px',
      color: '#fff',
      boxShadow: '0px 3px 5px 0px #dcdcdc',
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
      },
    },
  };
  return style;
};
