import { selectClientName } from '../common/utils';

export const brandVars = {
  bancaliv: {
    gq: 'https://bk.bancaliv.com/graphql',
    bk: 'https://bk.bancaliv.com/',
    app: 'https://app.bancaliv.com/',
    bt: 'butmili',
    admin: 'https://portal.bancaliv.com/',
  },
  credipress: {
    gq: 'https://bk.credipress.com/graphql',
    bk: 'https://bk.credipress.com/',
    app: 'https://app.credipress.com/',
    bt: 'butcredipress',
    admin: 'https://portal.credipress.com/',
  },
  fie: {
    gq: 'https://bk.fie.com.co/graphql',
    bk: 'https://bk.fie.com.co/',
    app: 'https://app.fie.com.co/',
    bt: 'butfiecredito',
    admin: 'https://portal2.fie.com.co/',
  },
  gym: {
    gq: 'https://bk.gmserviciosfinancieros.com.co/graphql',
    bk: 'https://bk.gmserviciosfinancieros.com.co/',
    app: 'https://app.gmserviciosfinancieros.com.co/',
    bt: 'butgym',
    admin: 'https://admin.gmserviciosfinancieros.com.co/',
  },
  tusolucion: {
    gq: 'https://bk.tusolucion.com.co/graphql',
    bk: 'https://bk.tusolucion.com.co/',
    app: 'https://app.tusolucion.com.co/',
    bt: 'butusolucion',
    admin: 'https://portal.tusolucion.com.co/',
  },
  finnvo: {
    gq: 'https://bk.finnvo.co/graphql',
    bk: 'https://bk.finnvo.co/',
    app: 'https://app.finnvo.co/',
    bt: 'butfinancoop',
    admin: 'https://portal.finnvo.co/',
  },
  fibanca: {
    gq: 'https://bk.fibanca.co/graphql',
    bk: 'https://bk.fibanca.co/',
    app: 'https://app.fibanca.co/',
    bt: 'butfibanca',
    admin: 'https://portal.fibanca.co/',
  },
  creditoo: {
    gq: 'https://backend.miliv2.com:4071/graphql',
    bk: 'https://backend.miliv2.com:4071/',
    app: 'https://app.creditoo.com.co/',
    bt: 'butcreditoo',
    admin: 'https://admin.creditoo.com.co/',
  },
  inversiones: {
    gq: 'https://bk.inversiones.com.co/graphql',
    bk: 'https://bk.inversiones.com.co/',
    app: 'https://app.inversiones.com.co/',
    bt: 'butinversiones',
    admin: 'https://admin.inversiones.com.co/',
  },
  nauw: {
    gq: 'https://bk.nauwcolombia.com/graphql',
    bk: 'https://bk.nauwcolombia.com/',
    app: 'https://app.nauw.com.co/',
    bt: 'butnauwcredito',
    admin: 'https://portal.nauwcolombia.com/',
  },
  liberate: {
    gq: 'https://bk.liberateco.online/graphql',
    bk: 'https://bk.liberateco.online/',
    app: 'https://liberateco.online/',
    bt: 'butliberate',
    admin: 'https://portal.liberateco.online/',
  },
  lenddi: {
    gq: 'https://bk.lenddi.co/graphql',
    bk: 'https://bk.lenddi.co/',
    app: 'https://app.lenddi.co/',
    bt: 'butlenddicredito',
    admin: 'https://admin.lenddi.co/',
  },
  freend: {
    gq: 'https://bk.freend.co/graphql',
    bk: 'https://bk.freend.co/',
    app: 'https://app.freend.co/',
    bt: 'butfreend',
    admin: 'https://portal.freend.co/',
  },
  mili: {
    gq: 'https://backqa.mili.com.co/graphql',
    bk: 'https://backqa.mili.com.co/',
    app: 'https://appqa.mili.com.co/',
    bt: 'pruebaawow',
    ossado: 'https://ossado.credipress.com',
    admin: 'https://qa.mili.com.co/',
    epaycoTest: true,
  },
  credinver: {
    gq: 'https://bk.credinver.com.co/graphql',
    bk: 'https://bk.credinver.com.co/',
    app: 'https://app.credinver.com.co',
    bt: 'butcredinver',
    admin: 'https://portal.credinver.com.co',
  },
  maxcredit: {
    gq: 'https://bk.maxcredit.com.co/graphql',
    bk: 'https://bk.maxcredit.com.co/',
    app: 'https://app.maxcredit.com.co/',
    bt: 'butmaxcredit',
    admin: 'https://portal.maxcredit.com.co',
  },
  fondo: {
    gq: 'https://fbk.elbancodelagente.co/graphql',
    bk: 'https://fbk.elbancodelagente.co/',
    app: '',
    bt: 'btfondocredito',
    admin: 'https://portal.elbancodelagente.co/',
  },
  sofi: {
    gq: 'https://bk.sofi.com.co/graphql',
    bk: 'https://bk.sofi.com.co/',
    app: 'https://app.sofi.com.co/',
    bt: 'butsofi',
  },
  zali: {
    gq: 'https://bk.zali.com.co/graphql',
    bk: 'https://bk.zali.com.co/',
    app: 'https://app.zali.com.co/',
    bt: 'butfinanciatech',
    admin: 'https://portal.zali.com.co',
  },
  libramoneda: {
    gq: 'https://bk.libramoneda.com/graphql',
    bk: 'https://bk.libramoneda.com/',
    app: 'https://app.libramoneda.com/',
    bt: 'butlibramoneda',
    admin: 'https://portal.libramoneda.com',
  },
  quickbank: {
    gq: 'https://bk.qbcol.com.co/graphql',
    bk: 'https://bk.qbcol.com.co/',
    app: 'https://app.qbcol.com.co/',
    bt: 'butquickbank',
    admin: 'https://portal.qbcol.com.co',
  },
  finanzas: {
    gq: 'https://bk.finanzas360.co/graphql',
    bk: 'https://bk.finanzas360.co/',
    app: 'https://app.finanzas360.co/',
    bt: 'butfinanzass',
    admin: 'https://portal.finanzas360.co',
  },
  orquidea: {
    gq: 'https://bk.orquidea.mili.com.co/graphql',
    bk: 'https://bk.orquidea.mili.com.co/',
    app: 'https://app.orquidea.mili.com.co/',
    bt: 'butorquidea',
    admin: 'https://orquidea.mili.com.co',
  },
  suvalor: {
    gq: 'https://bk.suvalor.com.co/graphql',
    bk: 'https://bk.suvalor.com.co/',
    app: 'https://app.suvalor.com.co/',
    bt: 'butsuvalor',
    admin: 'https://portal.suvalor.com.co',
  },
  crecevalor: {
    gq: 'https://bk.crecevalor.com.co/graphql',
    bk: 'https://bk.crecevalor.com.co/',
    app: 'https://app.crecevalor.com.co/',
    bt: 'butcrecevalor',
    admin: 'https://portal.crecevalor.com.co',
  },
};

const brandsQA = {
  miliqa: {
    gq: 'https://backqa2.mili.com.co/graphql',
    bk: 'https://backqa2.mili.com.co/',
    app: 'https://appqa2.mili.com.co/',
    bt: 'pruebaawow',
    ossado: 'https://ossado.credipress.com',
    admin: 'https://qa2.mili.com.co/',
    epaycoTest: true,
  },
  local: {
    gq: 'http://localhost:4000/graphql',
    bk: 'http://localhost:4000/',
    app: 'http://localhost:19006/',
    bt: 'pruebaawow',
    ossado: 'http://localhost:8000',
  },
};

export function brandUrls(): {
  gq: string;
  bk: string;
  app: string;
  bt: string;
  ossado?: string;
  admin?: string;
  epaycoTest?: boolean;
} {
  const client = selectClientName();

  if (client === 'bancaliv') {
    return brandVars.bancaliv;
  }
  if (client === 'orquidea') {
    return brandVars.orquidea;
  }
  if (client === 'credipress') {
    return brandVars.credipress;
  }
  if (client === 'fie') {
    return brandVars.fie;
  }
  if (client === 'gym') {
    return brandVars.gym;
  }
  if (client === 'fondo') {
    return brandVars.fondo;
  }
  if (client === 'tusolucion') {
    return brandVars.tusolucion;
  }
  if (client === 'finnvo') {
    return brandVars.finnvo;
  }
  if (client === 'creditoo') {
    return brandVars.creditoo;
  }
  if (client === 'inversiones') {
    return brandVars.inversiones;
  }
  if (client === 'nauw') {
    return brandVars.nauw;
  }
  if (client === 'fibanca') {
    return brandVars.fibanca;
  }
  if (client === 'liberate') {
    return brandVars.liberate;
  }
  if (client === 'lenddi') {
    return brandVars.lenddi;
  }
  if (client === 'credinver') {
    return brandVars.credinver;
  }
  if (client === 'freend') {
    return brandVars.freend;
  }
  if (client === 'maxcredit') {
    return brandVars.maxcredit;
  }
  if (client === 'sofi') {
    return brandVars.sofi;
  }
  if (client === 'zali') {
    return brandVars.zali;
  }
  if (client === 'libramoneda') {
    return brandVars.libramoneda;
  }
  if (client === 'quickbank') {
    return brandVars.quickbank;
  }
  if (client === 'finanzas') {
    return brandVars.finanzas;
  }
  if (client === 'suvalor') {
    return brandVars.suvalor;
  }
  if (client === 'crecevalor') {
    return brandVars.crecevalor;
  }
  // local & QA
  if (client === 'local') {
    return brandsQA.local;
  }
  if (client === 'miliqa') {
    return brandsQA.miliqa;
  }

  return brandVars.mili;
}
