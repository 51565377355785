import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, CircularProgress, Container, IconButton, Tooltip } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React from 'react';
import { TransitionsModalAlert } from '../../../../infrastructure/common/modalAlert';
import useWindowDimensions from '../../../../infrastructure/utils/hooks/useDimensions';
import { QuotaAdjustmentComponentProps } from './type.d';

const QuotaAdjustmentList: React.FC<QuotaAdjustmentComponentProps> = ({
  loading,
  handleDelete,
  isModalDelete,
  handleRowClick,
  quotaAdjustment,
}) => {
  const { h } = useWindowDimensions();
  const data = [...(quotaAdjustment?.getAllAdjustmentQuotas ?? [])].sort(
    (a, b) => a.aqUntil - b.aqUntil
  );
  const columns: GridColDef[] = [
    {
      field: 'aqFrom',
      headerName: 'DESDE',
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      cellClassName: 'cellRow',
    },
    {
      field: 'aqUntil',
      headerName: 'HASTA',
      headerAlign: 'center',
      align: 'center',
      cellClassName: 'cellRow',
      flex: 0.2,
    },
    {
      field: 'aqWhen',
      headerName: 'CUANDO',
      headerAlign: 'center',
      align: 'center',
      cellClassName: 'cellRow',
      flex: 0.2,
    },
    {
      field: 'aqSameMonths',
      headerName: 'MISMO MES',
      headerAlign: 'center',
      align: 'center',
      cellClassName: 'cellRow',
      flex: 0.2,
      valueFormatter: (params) => {
        return params.value ? 'SI' : 'NO';
      },
    },
    {
      field: 'action',
      headerName: 'ACCIÓN',
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }) => {
        return (
          <Container
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              height: '100%',
            }}>
            <Tooltip title="editar">
              <IconButton onClick={() => handleRowClick(row)} disabled={loading}>
                <EditIcon fontSize="small" color="primary" />
              </IconButton>
            </Tooltip>
            <Tooltip title="eliminar">
              <IconButton onClick={() => handleRowClick(row, 1)} disabled={loading}>
                <DeleteIcon fontSize="small" color="error" />
              </IconButton>
            </Tooltip>
          </Container>
        );
      },
    },
  ];

  return (
    <Box
      sx={{
        height: h(40),
        background: '#fff',
        width: '100%',
        border: 'none',
      }}>
      <TransitionsModalAlert
        state={isModalDelete}
        handleCloseModal={() => handleDelete()}
        width="25%"
        height="25%"
        title="Confirmar acción"
        paragraph="¿Estás seguro de continuar?"
        nameAccept={loading ? <CircularProgress size={15} /> : 'SI'}
        nameDecline="NO"
        disabledLoading={loading}
        onClickAccept={() => handleDelete(1)}
      />
      <DataGrid
        sx={{ border: 'none' }}
        columns={columns}
        getRowId={(adjustmentQuotas) => adjustmentQuotas?.aqId}
        rows={data || []}
        pageSize={20}
        density="compact"
      />
    </Box>
  );
};

export default QuotaAdjustmentList;
