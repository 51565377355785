import { useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import QuotaAdjustmentComponent from '../../../components/credits/quotaAdjustment';
import {
  CREATE_QUOTA_ADJUSTMENT,
  DELETE_QUOTA_ADJUSTMENT,
  GET_QUOTA_ADJUSTMENT,
  UPDATE_QUOTA_ADJUSTMENT,
} from './queries';
import schema from './schema';
import { TypeQuotaAdjustmentList } from './type.d';

const QuotaAdjustmentContainer: React.FC = () => {
  const { data: quotaAdjustment, refetch } = useQuery(GET_QUOTA_ADJUSTMENT, {
    fetchPolicy: 'cache-and-network',
  });

  const [valueList, setValueList] = useState<TypeQuotaAdjustmentList>();
  const [isModalDelete, setIsModalDelete] = useState(false);

  const {
    reset,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    values: valueList
      ? {
          to: valueList.aqUntil,
          when: valueList.aqWhen,
          from: valueList.aqFrom,
          sameMonth: !!valueList.aqSameMonths,
        }
      : {},
  });

  const [createQuotaAdjustment, { loading: createLoading }] = useMutation(CREATE_QUOTA_ADJUSTMENT);
  const [updateQuotaAdjustment, { loading: updateLoading }] = useMutation(UPDATE_QUOTA_ADJUSTMENT);
  const [deleteQuotaAdjustment, { loading: deleteLoading }] = useMutation(DELETE_QUOTA_ADJUSTMENT);

  const loading = createLoading || updateLoading || deleteLoading;

  const handleMutation = handleSubmit(async (data) => {
    const { from, sameMonth, to, when } = data;

    const variables = {
      input: {
        aqFrom: Number(from),
        aqSameMonths: sameMonth ? 1 : 0,
        aqUntil: Number(to),
        aqWhen: String(when),
      },
    };

    const handleSuccess = (message: string) => {
      toast(message, { autoClose: 5000, type: 'success', hideProgressBar: true });
    };

    const handleError = (message: string) => {
      toast(message, {
        autoClose: 3000,
        type: 'error',
        hideProgressBar: true,
      });
    };

    try {
      if (valueList?.aqId) {
        await updateQuotaAdjustment({ variables: { aqId: valueList.aqId, ...variables } });
        handleSuccess('Editado exitosamente');
        reset({});
      } else {
        await createQuotaAdjustment({ variables });
        handleSuccess('Creado exitosamente');
      }

      refetch();
      setValueList(undefined);
      setValue('from', undefined);
      setValue('sameMonth', undefined);
      setValue('to', undefined);
      setValue('when', undefined);
    } catch (error: any) {
      const errorMessage =
        error?.graphQLErrors?.[0]?.message || error?.message || 'Ha ocurrido una incidencia.';

      handleError(errorMessage);
    }
  });

  const handleDelete = async (type?: number) => {
    try {
      if (valueList?.aqId && type) {
        await deleteQuotaAdjustment({ variables: { aqId: valueList.aqId, input: { aqState: 0 } } });
        toast('Eliminado exitosamente', {
          autoClose: 5000,
          type: 'success',
          hideProgressBar: true,
        });
      }
      refetch();
      reset({
        to: undefined,
        when: undefined,
        from: undefined,
        sameMonth: undefined,
      });
      setIsModalDelete(false);
      setValueList(undefined);
      reset();
    } catch (error) {
      toast(`${error}`, {
        autoClose: 5000,
        type: 'success',
        hideProgressBar: true,
      });
    }
  };

  const handleRowClick = (row: TypeQuotaAdjustmentList, type?: number) => {
    setValueList(row);
    if (type) setIsModalDelete(true);
  };

  return (
    <QuotaAdjustmentComponent
      schema={schema}
      errors={errors}
      loading={loading}
      control={control}
      isModalDelete={isModalDelete}
      quotaAdjustment={quotaAdjustment}
      handleMutation={handleMutation}
      handleDelete={handleDelete}
      handleRowClick={handleRowClick}
    />
  );
};

export default QuotaAdjustmentContainer;
