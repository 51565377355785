import React, { useCallback, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { Button, useTheme } from '@mui/material';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { TModalAlert } from './types.d';
import { styleSheet } from './styled';
import useWindowDimensions from '../../utils/hooks/useDimensions';

export const TransitionsModalAlert: React.FC<TModalAlert> = ({
  state = false,
  title,
  subTitle,
  subTitleLong,
  paragraph,
  onClickAccept,
  onClickDecline,
  nameAccept,
  overflow,
  nameDecline,
  icon,
  handleCloseModal,
  disabledLoading,
  disabled,
  declineAction,
}) => {
  const { h } = useWindowDimensions();
  const [open, setOpen] = useState(state);
  const theme = useTheme();
  const styles = styleSheet(theme);

  const hide = useCallback(() => {
    setOpen(!state);
    handleCloseModal(false);
  }, [state]);

  useEffect(() => {
    setOpen(state);
  }, [state]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      // onClose={hide}
      closeAfterTransition>
      <Fade in={open}>
        <Box sx={{ ...styles.modal, maxHeight: h(60) }}>
          <Box sx={styles.boxIcon}>
            {icon || <WarningAmberRoundedIcon sx={{ fontSize: '120px', color: 'white' }} />}
          </Box>
          <Box sx={styles.boxInfo} overflow={overflow}>
            <Box sx={{ width: '100%' }}>
              <Typography sx={styles.title}>{title}</Typography>
              <Typography sx={styles.subTitle}>{subTitle}</Typography>
              <Typography sx={styles.subTitleLong}>{subTitleLong}</Typography>
              <Typography sx={styles.subTitle}>{paragraph}</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '20px',
              }}>
              <Button sx={styles.buttonOption} onClick={onClickAccept} disabled={disabledLoading}>
                {nameAccept}
              </Button>
              {nameDecline && (
                <Button
                  sx={styles.buttonOption}
                  onClick={declineAction === true ? onClickDecline : hide}
                  disabled={disabled}>
                  {nameDecline}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};
