import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { InputField } from '../../../../infrastructure/common/formAdapters';
import QuotaAdjustmentList from './list';
import { QuotaAdjustmentComponentProps } from './type.d';
import { InputAutocompleteAdapter } from '../../../../infrastructure/common/formAdapters/inputMaterialAutoCompleteAdapter';
import { styleSheet } from './styled';

const QuotaAdjustmentComponent: React.FC<QuotaAdjustmentComponentProps> = ({
  schema,
  errors,
  control,
  loading,
  handleDelete,
  isModalDelete,
  handleMutation,
  handleRowClick,
  quotaAdjustment,
}) => {
  const theme = useTheme();
  const styles = styleSheet(theme);

  return (
    <Box sx={styles.page}>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Typography sx={styles.title}>Ajuste de cuota</Typography>
        </Grid>
        <Grid item sm={12}>
          <Box sx={styles.card}>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <Typography sx={styles.subTitle}>Información de ajuste</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <InputField
                  label="Desde"
                  name="from"
                  control={control}
                  schema={schema}
                  error={errors}
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <InputField
                  label="Hasta"
                  name="to"
                  control={control}
                  schema={schema}
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControlLabel
                  control={
                    <Controller
                      name="sameMonth"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <Checkbox {...field} checked={field.value} disabled={loading} />
                      )}
                    />
                  }
                  label="¿Ajustar cuota mismo mes del desembolso?"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <InputAutocompleteAdapter
                  label="Cuando"
                  name="when"
                  control={control}
                  schema={schema}
                  error={errors}
                  disabled={loading}
                  options={[
                    { label: '30', value: '30' },
                    { label: '15', value: '15' },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sx={styles.end}>
                <Button onClick={handleMutation} sx={styles.button} disabled={loading}>
                  {loading ? (
                    <CircularProgress
                      style={{
                        height: '20px',
                        width: '20px',
                      }}
                    />
                  ) : (
                    <Typography variant="caption">Ajustar</Typography>
                  )}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item sm={12}>
          <Box sx={styles.card}>
            {/* /<Typography sx={styles.subTitle}>Lista</Typography> */}
            <QuotaAdjustmentList
              schema={schema}
              errors={errors}
              loading={loading}
              control={control}
              isModalDelete={isModalDelete}
              quotaAdjustment={quotaAdjustment}
              handleMutation={handleMutation}
              handleDelete={handleDelete}
              handleRowClick={handleRowClick}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default QuotaAdjustmentComponent;
