import React, { ChangeEvent, useEffect, useState } from 'react';
import { Autocomplete, List, ListItemButton, TextField } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { styled, useTheme } from '@mui/material/styles';
import validator from '../../../../lib/validator';

const CustomTextField = styled(TextField)(() => ({
  '& .MuiInputLabel-root': {
    fontSize: '1em',
  },
  '& .MuiInputBase-input': {
    fontSize: '1em',
  },
  '& .MuiInputBase-root': {
    fontSize: '1em',
    height: '38px',
  },
}));

const CustomListboxComponent = (value: any | { children: any; props: any }) => {
  return (
    <List
      {...value}
      sx={{
        width: '100%',
        maxWidth: '100%',
        bgcolor: 'background.paper',
        position: 'relative',
        overflow: 'auto',
        maxHeight: '300px',
        '& ul': { padding: 0 },
      }}>
      {value?.children}
    </List>
  );
};

export const InputAutocompleteAdapter = (props: {
  name: string;
  options?: { label: string; value: any }[];
  control: Control;
  keyLabel?: string;
  keyValue?: string;
  schema: validator.AnySchema;
  error?: any;
  label?: string;
  disabled?: boolean;
  onBlur?: any;
  borderBottom?: boolean;
  customOnChange?: (e?: ChangeEvent<HTMLInputElement>) => void;
  sx?: any;
}) => {
  const {
    name,
    options,
    control,
    label,
    schema,
    customOnChange,
    disabled,
    sx,
    onBlur,
    borderBottom = true,
    ...rest
  } = props;
  const theme = useTheme();
  const [inputOptions, setInputOptions] = useState<any>([]);

  useEffect(() => {
    if (options?.length) {
      setInputOptions([...options]);
    }
  }, [options]);

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate: (value) => schema.validateAt(name, { [name]: value }) }}
      render={({ field, fieldState }) => (
        <Autocomplete
          {...field}
          {...rest}
          sx={{ ...sx }}
          size="small"
          disabled={disabled}
          onOpen={() => {
            setInputOptions(options);
          }}
          isOptionEqualToValue={(option: any, value: any) => {
            return option?.value === value?.value;
          }}
          value={field?.value ? options?.find((x) => x?.value === field?.value) : null}
          options={inputOptions}
          getOptionLabel={(option: { label: string; value: any }) => option?.label}
          ListboxComponent={CustomListboxComponent}
          renderOption={(prop: any, option) => {
            return (
              <ListItemButton
                {...prop}
                key={`${Math.random()}`}>{`${option?.label}`}</ListItemButton>
            );
          }}
          onInputChange={(_e, value) => {
            setInputOptions(
              options?.filter((x) =>
                x?.label?.toString().toLowerCase().includes(value?.toLowerCase())
              )
            );
          }}
          onChange={(_, value: { label: string; value: any } | any) => {
            field.onChange(value?.value);
            if (customOnChange)
              customOnChange({
                target: { name, value: value?.value },
              } as any);
          }}
          onBlur={onBlur || field.onBlur}
          renderInput={(params) => (
            <CustomTextField
              {...params}
              variant="outlined"
              label={label || 'Selecciona una opción'}
              error={Boolean(fieldState.error?.message)}
              helperText={`${fieldState.error?.message || ''}`}
              sx={{
                mb: theme.spacing(1),
                fontSize: '1em',
                ...(!borderBottom ? { 'margin-bottom': '0px' } : {}),
              }}
            />
          )}
        />
      )}
    />
  );
};
